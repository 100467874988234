import Header from '../components/Header';
import Table from '../components/Table';

export default function Amis() {
  const Discord_Head = ["Discord", ""];
  const Art_Head = ["Art", ""];
  const Musique_Head = ["Musique", ""];
  const Twitter_Head = ["Twitter", ""];
  const Twitch_Head = ["Twitch", ""];
  const Autres_Head = ["Autres", ""];
  
  const Discord_ROWS = [
    {
      name: "Team Gaming LuWyJi",
      link: "https://discord.gg/TPygrdQ",
    },
    {
      name: "Pimousstopia",
      link: "https://discord.gg/rKQdYnn",
    },
    {
      name: "L'ébonite des monts (serveur RP/JDR dans le monde de The Elder Schroll en Alpha)",
      link: "https://discord.gg/tpRyS3Q",
    },
  ];
  const Art_ROWS = [
    {
      name: "Les peinture sur figurines de CeltikPainting",
      link: "https://www.instagram.com/celtikpainting/",
    },
    {
      name: "Les dessins de Carasan_art",
      link: "https://www.instagram.com/carasan_art/",
    },
  ];
  const Musique_ROWS = [
    {
      name: "BMonkey",
      link: "https://www.youtube.com/channel/UCFnLqZt8F5-NPwa53Z8_txA/videos",
    },
    {
      name: "Bad Fantasy	",
      link: "https://www.youtube.com/watch?v=q6gsBQBfWh8",
    },
  ];
  const Twitter_ROWS = [
    {
      name: "",
      link: "",
    },
  ];
  const Twitch_ROWS = [
    {
      name: "Pimousse_",
      link: "https://www.twitch.tv/pimousse_",
    },
    {
      name: "Tiadriel",
      link: "https://www.twitch.tv/tiadriel",
    },
    {
      name: "Arachmet",
      link: "https://www.twitch.tv/arachmet",
    },
  ];
  const Autres_ROWS = [
    {
      name: "TopazDev | Modpack minecraft et des projets dev par Azerxim",
      link: "https://topazdev.fr/",
    },
    {
      name: "Indexos",
      link: "https://github.com/IndexosCentralOrganization/Indexos-The-Great",
    },
    {
      name: "Le logiciel d'actualités de Gnouf1",
      link: "https://github.com/gnouf1/ActualiT",
    },
    {
      name: "Last Shelter | Un jeu vidéo en développement par Shelll",
      link: "https://github.com/Shellloman/LastShelter",
    },
    {
      name: "PowerCut Inc | Un jeu développé par un ami de DrNoob",
      link: "https://store.steampowered.com/app/944440/POWERCUT_Inc/",
    },
  ];
  return (
    <div className='flex fd-col gap-10'>
      <Header title="Amis" />
      <div className='flex fd-col gap-10' style={{"margin": "20px"}}>
        <div>
          <h2 style={{"fontSize": "35px"}}><strong>Nos amis</strong></h2>
          <h3 style={{"paddingLeft": "2%"}}>
            Chez Bastion on fait de la pub pour nos amis !
          </h3>
          <p style={{"paddingLeft": "2%"}}>
            Ici nous avons souhaité réunir ensemble les liens vers les créations et projets de nos amis
          </p>
        </div>

        <div className='flex fd-col gap-5'>
          {/* <Table HEAD={Discord_Head} ROWS={Discord_ROWS} bg_color="black" color="white" /> */}
          {/* <Table HEAD={Art_Head} ROWS={Art_ROWS} bg_color='amber-300' /> */}
          {/* <Table HEAD={Musique_Head} ROWS={Musique_ROWS} bg_color='lime' color='white' /> */}
          {/* <Table HEAD={Twitter_Head} ROWS={Twitter_ROWS} bg_color='blue' color='white' /> */}
          {/* <Table HEAD={Twitch_Head} ROWS={Twitch_ROWS} bg_color='purple' color='white' /> */}
          {/* <Table HEAD={Autres_Head} ROWS={Autres_ROWS} bg_color='red' color='white' /> */}
        </div>
      </div>
    </div>
  )
}