import Header from '../components/Header.js'
import LayoutGri2_48 from '../components/LayoutGri2_48.js'
import LayoutGri2_84 from '../components/LayoutGri2_84.js'

const textHome1 = ""
const textHome2 = ""
const textHome3 = ""

export default function Home() {
  return (
    <div className='flex fd-col gap-10'>
      <Header title="Accueil" />
      <LayoutGri2_84 image="/home/1.png" text={textHome1} title="Qui sommes nous ?">
        Nous sommes une communauté fondée en Mai 2018 qui se donne pour objectif la mise en relation de joueurs francophones
        autour d'une passion commune qu'est le jeu vidéo ou plus largement l'univers geek.
        Nous avons quelque serveurs de jeux qui sont tous à la disposition des membres du Bastion.<br/>
        En sommes le but est de passer du temps avec des gens qui ont la même passion et dans la bonne humeur !
      </LayoutGri2_84>
      <LayoutGri2_48 image="/home/2.png" title="Pourquoi nous rejoindre ?">
        En nous rejoignant vous vous assurez de rejoindre une team
        dont les membres sont sympathiques et qui sont là pour s'amuser.
        De plus notre communauté grandissante permet que les gens soient
        assez proches et qu'il y est une ambiance globalement très cool.
      </LayoutGri2_48>
      <LayoutGri2_84 image="" title="Où nous trouver ?">
          Pour cela rien de plus simple !
          Il suffit de nous rejoindre sur notre discord ou sur l'une de nos guildes en jeu !<br/>
          Bien que notre discord reste central il est possible d'être dans une de nos teams sans être sur le discord.
      </LayoutGri2_84>
    </div>
  )
}
