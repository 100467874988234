import React, { Children } from "react"

export default function LayoutGri2_84({image, title, children}) {
  const isImageNone = (image) => {
    if (image != "") {   
      return (<div className="col-span-4 lg:col-span-4"><img src={image} alt="image" /></div>)
    }
    return ''
  }

  return (
    <>
      <section>
        <div className="container px-6 m-auto">
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
            <div className="flex fd-col gap-5 col-span-8">
              <h1 className="title">{title}</h1>
              <p>{children}</p>
            </div>
            {isImageNone(image)}
          </div>
        </div>
      </section>
    </>
  )
}