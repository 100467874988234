import React from "react"

export default function Footer() {
  return (
    <>
      <footer className="w-full text-indigo-500" style={{marginTop: "50px"}}>
        <div className="py-4 text-sm border-t border-indigo-200/90 bg-indigo-100/80">
          <div className="container px-6 mx-auto">
            <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
              <div className="flex gap-2 col-span-2 md:col-span-4 lg:col-span-6">
                <span>Copyright 2024</span>
                <a href="https://topazdev.fr" target="_blank">TopazDev</a>
              </div>
              <nav
                aria-labelledby="footer-social-links-light"
                className="col-span-2 text-right md:col-span-4 lg:col-span-6"
              >
                <h2 className="sr-only" id="footer-social-links-light">
                  Social Media Links
                </h2>
                <ul className="flex items-center justify-end gap-4">
                  <li>
                    <a
                      href="https://github.com/Azerxim"
                      target="_blank"
                      className="transition-colors duration-300 hover:text-indigo-600 focus:text-indigo-700"
                    >
                      <i className="fab fa-github"></i>
                      <span style={{display: "none"}}>Github</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitch.tv/azerxim"
                      target="_blank"
                      className="transition-colors duration-300 hover:text-indigo-600 focus:text-indigo-700"
                    >
                      <i className="fab fa-twitch"></i>
                      <span style={{display: "none"}}>Twitch</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/channel/UC3BfRqB0yM1iCxmNv3ig_Nw"
                      target="_blank"
                      className="transition-colors duration-300 hover:text-indigo-600 focus:text-indigo-700"
                    >
                      <i className="fab fa-youtube"></i>
                      <span style={{display: "none"}}>Youtube</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}