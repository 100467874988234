import Header from '../components/Header'

export default function Fonctionnement() {
  return (
    <div className='flex fd-col gap-10'>
      <Header title="Fonctionnement" />
      <div style={{"margin": "20px"}}>
        <h2 style={{"fontSize": "35px"}}><strong>Notre fonctionnement</strong></h2>
        <h3 className="" style={{"paddingLeft": "2%"}}>
          Le serveur discord fonctionne en grades:
        </h3>
        <br/>
        <div className='flex fd-col gap-5'>

          <div className="box" style={{"boxShadow": "0 2px 3px #bb0202, 0 0 0 1px #bb0202", "borderRadius": "10px", "padding": "15px"}}>
            <p>
              <strong>Baron du Bastion</strong>
              <br/>
              Le Grand Baron du Bastion est le fondateur de celui-ci, il fait partit de l'administration et a toutes les clefs du serveur discord comme des serveurs de jeux.
            </p>
          </div>

          <div className="box" style={{"boxShadow": "0 2px 3px #4f789e, 0 0 0 1px #4f789e", "borderRadius": "10px", "padding": "15px"}}>
            <p>
              <strong>Les Inquisiteurs du Bastion</strong>
              <br/>
              Ils sont quatres et ils sont là pour gérer le serveur, bannir ceux qui ne respectent pas les règles, intervenir en cas de conflit etc... Ce sont les modérateurs du serveurs, si vous avez un problème avec un autre membre du discord ou d'une guilde voyez avec eux, c'est leur job.
            </p>
          </div>

          {/* <div className="box" style={{"boxShadow": "0 2px 3px #1d971f, 0 0 0 1px #1d971f", "borderRadius": "10px", "padding": "15px"}}>
            <p>
							<strong>Les Ambassadeurs</strong>
							<br/>
							Ce sont les représentants et les gestionnaires d'une communautés en particulier. Ils sont chargés de gérer la guilde IG, d'organiser des évents dans leur communnauté sur leur jeu mais aussi d'aggrandir la communauté du Bastion sur leur jeu.
							Tout le monde peut devenir amabssadeur d'un jeu où il n'y en a pas, il faut faire la demande aurpès des inquisiteurs et du Baron.
						</p>
          </div> */}

          <div className="box" style={{"boxShadow": "0 2px 3px #2f2f2f, 0 0 0 1px #f2f2f2", "borderRadius": "10px", "padding": "15px"}}>
            <p>
              <strong>Les Joueurs</strong>
              <br/>
              <p>Les joueurs sont plus que la Colonne vertébrale du Bastion, ils sont ses cellules. Le rôle de joueur est automatiquement attribué aux joueurs nouvellement arrivés. Ils n'ont alors accès qu'a un nombre limité de salon, pour avoir accès aux autres il faut qu'ils s'ajoutent les rôles correspondant aux jeux qu'ils souhaitent afficher sur le Bastion.</p>
            </p>
          </div>

        </div>

        {/* <div className="box">
          <article className="media">
            <div className="media-content">
              <div className="content">
                <p>
                  <strong>Les Joueurs</strong>
                  <br/>
                  <p>Les joueurs sont plus que la Colonne vertébrale du Bastion, ils sont ses cellules. Le rôle de joueur est automatiquement attribué aux joueurs nouvellement arrivés. Ils n'ont alors accès qu'a un nombre limité de salon, pour avoir accès aux autres il faut qu'ils s'ajoutent les rôles correspondant aux jeux qu'ils souhaitent afficher sur le Bastion.</p>
                </p>
              </div>
            </div>
          </article>
        </div> */}
      </div>
    </div>
  )
}