// /src/App.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NavbarIcons from './components/NavbarIcons';
import Footer from './components/Footer';
import Home from './pages/Home';
import Fonctionnement from './pages/Fonctionnement';
import Agenda from './pages/Agenda';
import Amis from './pages/Amis';


export default function App() {
  return (
    <div>
      <NavbarIcons />
      <Routes>
        <Route path="/" element = {<Home />}></Route>
        <Route path="/fonctionnement" element = {<Fonctionnement />}></Route>
        <Route path="/agenda" element = {<Agenda />}></Route>
        <Route path="/amis" element = {<Amis />}></Route>
      </Routes>
      <Footer/>
    </div>
  );
}