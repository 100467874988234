import React from "react"

export default function Header({title}) {
    return (
        <section className="flex-center-full fd-col gap-10" style={{background: "#222222 url(/bastion_wallpaper.png) no-repeat center top", borderBottom: "#313131 solid", height: "300px"}}>
            <img alt="" src="/bastion_w.png" width="200"/>
            <h2 className="white" style={{fontSize: "22px"}}>{title}</h2>
            <a href="https://discord.gg/nUFwE9S" target="_blank" className="">
                <button className="inline-flex h-10 items-center justify-center gap-2 whitespace-nowrap rounded bg-indigo-500 px-5 text-sm font-medium tracking-wide text-white">
                    <i className="fab fa-discord"></i>
                    <span>Nous rejoindre</span>
                </button>
            </a>
        </section>
    )
}