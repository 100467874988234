import Header from '../components/Header.js'

export default function Agenda() {
  return (
    <div className='flex fd-col gap-10'>
      <Header title="Agenda" />
      <div style={{"margin": "20px"}}>
        <h2 style={{"fontSize": "35px"}}><strong>L'agenda</strong></h2>
        <h3 style={{"paddingLeft": "2%"}}>
          Ici vous pouvez retrouver tous les futurs events sur Bastion, nos serveurs et différents jeux.
        </h3>
      </div>
    </div>
  )
}